import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public showHeaderSearchButton: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public openHeaderSearch: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public is404: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isRedirect: BehaviorSubject<any> = new BehaviorSubject(null);
  public isDenied: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() {
  }
}
