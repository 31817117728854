import { Injectable } from '@angular/core';
import { Resource } from '../../../Library/resource/resource.model';
import { Media, MediaAdapter } from '../../../Library/media/models/media.model';
import { BaseAdapter } from '../../../Library/adapter/adapter.model';

export class Author extends Resource<Request> {
  id: number;
  name: string;
  status: string;
  full_name: string;
  image: Media;

  public constructor(data?: {}) {
    super(data);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthorAdapter extends BaseAdapter<Author> {

  constructor(private mediaAdapter: MediaAdapter) {
    super(Author);
  }

  adapt(data: any): Author {
    const item = super.adapt(data);

    if (item.image) {
      item.image = this.mediaAdapter.adapt(item.image);
    }

    return item;
  }
}
