import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../Library/services/global.service';
import { BehaviorSubject } from 'rxjs';
import { SeoService } from '../../../Library/services/seo/seo.service';
import { RouteName } from '../../../Library/routes';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AppLayoutComponent implements OnInit {
  public is404Subject: BehaviorSubject<boolean>;

  constructor(
    private seoService: SeoService,
    globalService: GlobalService,
  ) {
    this.is404Subject = globalService.is404;
  }

  ngOnInit(): void {
    this.is404Subject.subscribe(is404 => {
      if (is404) {
        this.seoService.refreshWithDefault();
      }
    });
  }

}
